import { AxiosResponse } from 'axios';
import { navigate } from 'gatsby';

interface GeneratePaymentCodeResponse {
  orderId: number;
  codePagseguro: string;
  message: string;
}

export const responseHandler = (response: AxiosResponse<GeneratePaymentCodeResponse>) => {
  if (!response?.data?.codePagseguro || response.status !== 201) {
    const {
      data: { message },
    } = response;
    throw new Error(message || 'Desculpe, mas esse produto está indisponível');
  }
  const {
    data: { orderId, codePagseguro },
  } = response;
  return {
    code: codePagseguro,
    orderId,
  };
};

export const paymentCallbackSuccess = {
  success: () => {
    navigate('/meus-pedidos');
  },
};
