import React from 'react';
import { Datasheet } from './components/Datasheet';
import styles from './DataAdvertisement.module.css';

export interface ProductAdvertisementProps {
  category: string;
  productType: string;
  productState: string;
  warranty: string;
  type: string;
  productDescription: string;
}
export const ProductAdvertisement = ({
  productDescription,
  ...dataSheet
}: ProductAdvertisementProps) => (
  <section className="pb32 pb48-ns pb0-m pb0-l relative">
    <Datasheet data={dataSheet} />
    <section className="pt24">
      <h2 className="f16 fw6 abbey ma0 mb4">Descrição</h2>
      <div className={styles.textAbout}>
        <p className="f14 fw3 abbey ma0 lh-copy mw6">{productDescription}</p>
      </div>
    </section>
  </section>
);
