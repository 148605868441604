import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CallNow } from '../../../components/CallNow';
import { Container } from '../../../components/Container';
import { GridCell } from '../../../components/GridCell';
import { LoaderFullPage } from '../../../components/Loaders';
import { MediaMobile, MediaMedium } from '../../../components/MediaWrapper';
import { ModalSuccessAndError } from '../../../components/ModalSuccessAndError';
import { Observations } from '../../../components/Observations';
import { SharedModal } from '../../../components/SharedModal';
import { SummaryShopData } from '../../../components/ShopData';
import { redirectCustomerToLogin } from '../../../utils/auth';
import { loadPagseguroScript } from '../../../utils/scripts';
import { Gallery } from '../../Advertisement/Gallery';
import { Breadcrumb } from '../../Faq/components/Breadcrumb';
import { Hyperlink } from '../../Faq/types';
import { Footer } from '../../Footer';
import { SectionSix } from '../../Home/components/SectionSix';
import { TypeUserStore } from '../../Login/components/SignIn/store/user';
import { Menu } from '../../Menu';
import { Top } from '../../Menu/components/MenuAd';
import {
  ProductAdvertisement,
  ProductAdvertisementProps,
} from '../DataAdvertisement/ProductAdvertisement';
import { ProductCoreInformation } from '../Product/components/ProductCoreInformation';
import { AdvertisementService } from '../services/AdvertisementService';
import { formatterProductAdvertisement } from '../utils/FormatterAdvertisement';
import { responseHandler, paymentCallbackSuccess } from '../utils/paymentHandler';
import { ModalConfirmation } from './components/ModalConfirmation';

interface Props {
  advertisement: any;
  queryParam: string;
  user?: TypeUserStore;
}
@inject('user')
@observer
export class ProductAdvertisementModule extends Component<Props> {
  state = {
    data: {
      productId: 0,
      gallery: { photos: [] },
      name: '',
      price: '',
      limitCount: '',
      dataSheet: {
        type: '',
        category: '',
        productType: '',
        productState: '',
        warranty: '',
        productDescription: '',
      },
      user: { companyName: '', name: '', phone: '', whatsapp: '', fantasyName: '', type: '' },
      address: {
        bgColor: '',
        urlImg: '',
        nameStore: '',
        phone: '',
        whatsapp: '',
        s3: '',
        address: {
          city: '',
          cepAddress: '',
          district: '',
          street: '',
          additionalAddressData: '',
        },
        type: '',
        id: 0,
      },
      shareData: {},
      hasToCall: false,
    },
    loading: true,
    openModal: false,
    adId: 0,
    openErrorModal: false,
    openModalConfirmation: false,
    amount: 0,
    modalConfig: {
      title: 'Ops!',
      subTitle: 'Ocorreu um erro ao completar sua ação.',
      success: false,
    },
    paymentScriptStatus: {
      error: false,
      loaded: false,
    },
    isTransactionLoading: false,
  };

  async componentDidMount() {
    const { advertisement } = this.props;
    const newData = formatterProductAdvertisement(advertisement);
    const paymentScriptStatus = await loadPagseguroScript();
    this.setState({ data: newData, loading: false, paymentScriptStatus });
  }

  toggleModalStore = (e?: any) => {
    e.stopPropagation();
    const { openModal } = this.state;
    this.setState({ openModal: !openModal });
  };

  closeErrorModal = () => {
    this.setState({ openErrorModal: false });
  };

  closeModalConfirmation = () => {
    this.setState({ openModalConfirmation: false });
  };

  handleBuyProduct = (amount: string) => {
    this.setState({ openModalConfirmation: true, amount: Number(amount) });
  };

  buyProduct = async () => {
    const { user } = this.props;

    if (!user?.profile?.token) {
      return redirectCustomerToLogin();
    }

    const {
      data: { productId },
      paymentScriptStatus,
      amount,
    } = this.state;

    this.setState({ isTransactionLoading: true, openModalConfirmation: false });
    const response = await AdvertisementService.generatePaymentCode({
      productId,
      amount: Number(amount),
    });

    try {
      const { code } = responseHandler(response);
      if (paymentScriptStatus.loaded && !paymentScriptStatus.error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        PagSeguroLightbox(code, {
          ...paymentCallbackSuccess,
          abort: () => this.setState({ isTransactionLoading: false }),
        });
      } else {
        this.setState({ openErrorModal: true, isTransactionLoading: false });
      }
    } catch (error) {
      this.setState((prevState: any) => {
        const { modalConfig } = prevState;
        return {
          openErrorModal: true,
          isTransactionLoading: false,
          modalConfig: {
            ...modalConfig,
            subTitle: error.message,
          },
        };
      });
    }
  };

  render() {
    const {
      data,
      loading,
      openModal,
      openErrorModal,
      modalConfig,
      isTransactionLoading,
      openModalConfirmation,
    } = this.state;
    const { gallery, dataSheet, address, hasToCall, shareData, price, name, limitCount } = data;
    const dataValue = `${address.nameStore}`;

    if (loading) {
      return <LoaderFullPage />;
    }

    const whatsappNumber = (
      <CallNow
        adId={this.props?.advertisement?.id}
        hasToCall={hasToCall}
        name={address.nameStore}
        type="big"
        numberPhone={address.whatsapp}
        dataValue={dataValue}
      />
    );

    const productCoreInformation = (
      <ProductCoreInformation
        name={name}
        fullPrice={price}
        numberMaxProductsPerOrder={Number(limitCount)}
        buyProduct={this.handleBuyProduct}
        loading={isTransactionLoading}
      >
        {whatsappNumber}
      </ProductCoreInformation>
    );

    const { category, productType } = dataSheet;

    const breadcrumbDataSource: Hyperlink[] = [
      {
        to: '/',
        text: 'Acessórios',
      },
      {
        to: '/',
        text: category,
      },
      {
        to: '/',
        text: productType,
      },
    ];

    const breadCrumb = (
      <Breadcrumb
        dataSource={breadcrumbDataSource}
        hasDirectPage={false}
        customClass="mv24-ns mv12"
      />
    );

    const galleryComponent = (
      <Gallery
        gallery={gallery.photos}
        onSharedClick={this.toggleModalStore}
        type="product"
        withoutMainPhoto
      />
    );

    return (
      <>
        <div className="db db-ns dn-m dn-l">
          <Top handleShare={this.toggleModalStore} />
        </div>
        <div className="dn db-m db-l">
          <Menu menuHasTwoColors={false} />
        </div>
        <div className="bg-wild-sand">
          <MediaMobile>{galleryComponent}</MediaMobile>
          <Container>
            <GridCell
              width={[1, 1, 1]}
              className="mt12 mt72-m mt72-l center mb48 mb48-m pb8-m pb24-l flex-ns"
            >
              <GridCell width={[0, 0, 0, 1 / 12]} />
              <GridCell width={[1, 7 / 12, 7 / 12, 6 / 12]}>
                <MediaMedium>
                  {breadCrumb}
                  {galleryComponent}
                </MediaMedium>
                <MediaMobile>
                  {breadCrumb}
                  {productCoreInformation}
                </MediaMobile>
                <ProductAdvertisement {...(dataSheet as unknown as ProductAdvertisementProps)} />
              </GridCell>
              <GridCell
                width={[1, 5 / 12, 5 / 12, 4 / 12]}
                className="flex-column-ns ml12-ns ml24-m ml24-l mt48-ns"
              >
                <MediaMedium>{productCoreInformation}</MediaMedium>
                <div className="mt24 mt32-m">
                  <SummaryShopData dataAddress={address} telephoneAsLink />
                </div>
              </GridCell>
              <GridCell width={[0, 0, 0, 1 / 12]} />
            </GridCell>
            <SectionSix store={{ userId: address.id }} />
            <Observations />
          </Container>
          <div className="mb48 mb0-m mb0-l pb8 pb0-m pb0-l">
            <Footer />
          </div>
        </div>
        <SharedModal
          typeShared="loja"
          isOpenModal={openModal}
          controlModal={this.toggleModalStore}
          dataShare={shareData}
        />
        <ModalSuccessAndError
          {...modalConfig}
          isOpen={openErrorModal}
          actionBtn={this.closeErrorModal}
          closeModal={this.closeErrorModal}
          actionBtnSecondary={this.closeErrorModal}
          textBtnClose="OK"
        />
        <ModalConfirmation
          isOpen={openModalConfirmation}
          address={address}
          handleConfirm={this.buyProduct}
          handleCancel={this.closeModalConfirmation}
        />
      </>
    );
  }
}
