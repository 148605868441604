const isRunningInBrowser = () => typeof window !== 'undefined';

export const isLoaded = (src: string) =>
  isRunningInBrowser() && Boolean(document.querySelector(`script[src="${src}"]`));

export const onLoad = (scriptRef?: HTMLScriptElement) => () => {
  if (scriptRef) {
    scriptRef.removeEventListener('load', onLoad());
    scriptRef.removeEventListener('error', onError());
  }
  return {
    loaded: true,
    error: false,
  };
};

export const onError = (scriptRef?: HTMLScriptElement) => () => {
  if (scriptRef) {
    scriptRef.remove();
  }
  return {
    loaded: true,
    error: true,
  };
};

export const loadScript = (src?: string) =>
  new Promise(resolve => {
    if (!isRunningInBrowser() || !src) {
      return resolve({ loaded: false, error: true });
    }
    if (isLoaded(src)) {
      return resolve(onLoad()());
    }
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    script.addEventListener('load', () => {
      return resolve(onLoad(script)());
    });
    script.addEventListener('error', () => {
      return resolve(onError(script)());
    });
    document.body.appendChild(script);
  });

export const loadPagseguroScript = () => loadScript(process.env.PAGSEGURO_SANDBOX);
