import React from 'react';
import _map from 'lodash/map';
import { LinkRed } from '../../../../components/Link';
import { Hyperlink } from '../../types';
import styles from './style.module.css';

interface BreadcrumbProps {
  dataSource: Hyperlink[];
  hasDirectPage?: boolean;
  customClass?: string;
}

interface CrumbProps {
  hyperlink: Hyperlink;
}

export const Breadcrumb = ({
  dataSource,
  hasDirectPage = true,
  customClass = '',
}: BreadcrumbProps) => {
  const crumbs = _map(dataSource, (hyperlink: Hyperlink, i: number) => {
    const isLastCrumb = i + 1 === dataSource.length;
    const Component = isLastCrumb && hasDirectPage ? CurrentPageCrumb : PageCrumb;

    return (
      <li key={hyperlink.text} className={styles.crumb}>
        <Component hyperlink={hyperlink} />
      </li>
    );
  });

  const breadCrumbClassName = customClass || 'mv32 mv48-m mv48-l';

  return (
    <nav className={breadCrumbClassName} aria-label="Breadcrumb">
      <ol className="ma0 pa0 list mb24 flex items-center">{crumbs}</ol>
    </nav>
  );
};

const PageCrumb = ({ hyperlink }: CrumbProps) => {
  const { to, text } = hyperlink;

  return (
    <LinkRed to={to} className="db">
      {text}
    </LinkRed>
  );
};

const CurrentPageCrumb = ({ hyperlink }: CrumbProps) => {
  const { text } = hyperlink;

  return <span className="fw7 f12 lh-copy db abbey">{text}</span>;
};
