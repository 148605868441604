// tslint:disable:jsx-no-multiline-js
import React, { useState } from 'react';
import { OutlineButton } from '../../../../components/Button';
import { PrimaryButton } from '../../../../components/Button/Primary';
import { Checkbox } from '../../../../components/Checkbox';
import { GridCell } from '../../../../components/GridCell';
import { AddressActions } from '../../../../components/ShopData/common/SeeAddress/AddressActions';
import { StyledModal } from '../../../../components/StyledModal';
import { callAll } from '../../../../utils/callAll';
import { cellPhone } from '../../../../utils/mask';

interface ModalConfirmationProps {
  isOpen: boolean;
  address: any;
  handleConfirm: (e?: any) => void;
  handleCancel: (e?: any) => void;
}

export const ModalConfirmation = ({
  isOpen,
  address,
  handleConfirm,
  handleCancel,
}: ModalConfirmationProps) => {
  const [acceptanceTerm, setAcceptanceTerm] = useState(false);

  const {
    phone,
    whatsapp,
    address: { city, cepAddress, district, street, additionalAddressData },
  } = address;

  const addresCopy = {
    city,
    cepAddress,
    district,
    street,
    additionalAddressData,
  };

  const formatedPhone = cellPhone(phone);
  const formatedWhatsapp = cellPhone(String(whatsapp));

  const linkPhone = `tel:${phone}`;
  const linkWhatsapp = `https://api.whatsapp.com/send?phone=${whatsapp}`;

  const formatedAddress = `${street} - ${district} - ${city}`;

  const handleAcceptanceTerm = () => {
    setAcceptanceTerm(!acceptanceTerm);
  };

  const closeModal = () => {
    setAcceptanceTerm(false);
    handleCancel();
  };

  return (
    <StyledModal isOpen={isOpen} closeModal={closeModal}>
      <div className="abbey pa16 pb32 pa32-ns">
        <p className="ma0 fw6 f24 mb24">Atenção</p>
        <p className="ma0 fw2 f20 mb12">
          O produto deverá ser retirado na loja ou se preferir, combine direto com o vendedor.
        </p>

        <div className="mb24">
          <p className="ma0 fw3 f20 mb12">Contato da loja:</p>

          <p className="ma0 fw2 f20 mb12">
            Telefone:{' '}
            <a
              target="_blank"
              title={`Telefone ${phone}`}
              className="link abbey underline"
              href={linkPhone}
              rel="noreferrer"
            >
              {formatedPhone}
            </a>
          </p>

          {whatsapp && (
            <p className="ma0 fw2 f20">
              Whatsapp:{' '}
              <a
                target="_blank"
                title={`Whatsapp ${whatsapp}`}
                className="link abbey underline"
                href={linkWhatsapp}
                rel="noreferrer"
              >
                {formatedWhatsapp}
              </a>
            </p>
          )}
        </div>

        {addresCopy.cepAddress !== '--' && (
          <div className="mb24">
            <p className="ma0 fw3 f20 mb12">Endereço da loja: </p>
            <p className="ma0 fw2 f20">{formatedAddress}</p>
            <AddressActions address={addresCopy} />
          </div>
        )}

        <div className="mb24">
          <Checkbox
            id="acceptanceTerm"
            textLabel="Li e concordo que é necessário retirar o produto na loja ou combinar com o vendedor."
            checked={acceptanceTerm}
            onChange={handleAcceptanceTerm}
            value="PermanecerConectado"
          />
        </div>

        <GridCell className="flex-ns items-end-ns justify-center pb8 pb0-ns">
          <PrimaryButton
            resetClass
            classButton="ph32 f18 fw7 ttu"
            onClick={callAll(handleConfirm, closeModal)}
            title="OK"
            aria-label="OK"
            disabled={!acceptanceTerm}
          >
            Confirmar
          </PrimaryButton>
          <OutlineButton
            className="mt16 ml16-ns"
            onClick={closeModal}
            title="Cancelar"
            aria-label="Cancelar"
          >
            Cancelar
          </OutlineButton>
        </GridCell>
      </div>
    </StyledModal>
  );
};
