// tslint:disable:jsx-no-multiline-js
import React, { useState } from 'react';
import cx from 'classnames';
import { Formik } from 'formik';
import { Icon } from '../../../../components/Icons';
import { Option } from '../../../../components/Select';
import { formatCurrency } from '../../../../utils/formatNumber';
import styles from '../AdProduct.module.css';

interface InstallmentsProps {
  numberOfInstallments?: number;
  installmentsValue?: string | number;
}

interface NumberOfProductsProps {
  numberMaxProductsPerOrder: number;
}

interface ProductCoreInformationProps extends InstallmentsProps, NumberOfProductsProps {
  name: string;
  fullPrice: string | number;
  buyProduct(numberOfProducts: string): void;
  loading: boolean;
  children?: React.ReactNode;
}

const Installments = ({ numberOfInstallments, installmentsValue }: InstallmentsProps) => {
  if (!numberOfInstallments || !installmentsValue) {
    return null;
  }
  return (
    <div className="flex flex-column mb24">
      <div className="flex items-center">
        <Icon width={20} height={20} title="Icone cartão de crédito" name="SvgIconCreditCard" />
        <span className="f14 ml8">
          em até {numberOfInstallments}x de {formatCurrency(installmentsValue)} sem juros
        </span>
      </div>

      <a
        className="f12 mt12 underline pointer"
        title="Ver condições de parcelamento"
        aria-label="Ver condições de parcelamento"
      >
        Ver condições
      </a>
    </div>
  );
};

interface NumberOfProducts {
  numberOfProducts: Option;
}

export const ProductCoreInformation = ({
  name,
  fullPrice,
  numberOfInstallments,
  installmentsValue,
  numberMaxProductsPerOrder,
  buyProduct,
  children,
}: ProductCoreInformationProps) => {
  const numberOfProductsOptions = Array.from(Array(numberMaxProductsPerOrder).keys()).map(value => {
    const formattedValue = ++value > 9 ? `${value}` : `0${value}`;
    return {
      label: formattedValue,
      value,
    };
  });

  const containerStyle = cx('pr24-ns pl24-ns pb32', styles.productCoreCard);
  const [noOfProducts] = useState(numberOfProductsOptions[0]);

  const handleProductOrder = (
    { numberOfProducts }: NumberOfProducts,
    { setSubmitting }: { setSubmitting(value: boolean): void },
  ) => {
    setSubmitting(false);
    buyProduct(noOfProducts.label || numberOfProducts.label);
  };

  return (
    <section className={containerStyle}>
      <Formik initialValues={{ numberOfProducts: noOfProducts }} onSubmit={handleProductOrder}>
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className="flex flex-column abbey">
              <h1 className="f36-m f36-l f20">{name}</h1>
              <h2 className="mt0 f36-m f36-l f24">{formatCurrency(fullPrice)}</h2>
              <Installments
                numberOfInstallments={numberOfInstallments}
                installmentsValue={installmentsValue}
              />
              {/* <div style={{ width: 182 }}>
                <Field {...normalizeFieldData} component={Select} />
              </div> */}
              {/* <span className="f12 mt4 mb24">
                Máximo {numberMaxProductsPerOrder}{' '}
                {numberMaxProductsPerOrder === 1 ? 'unidade' : 'unidades'} por compra.
              </span>
              <Button
                green={true}
                type="submit"
                aria-label="Comprar produto"
                disabled={isSubmitting}
                loading={loading}
              >
                COMPRAR
              </Button> */}
              {children}
            </form>
          );
        }}
      </Formik>
    </section>
  );
};
