import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import _first from 'lodash/first';
import _last from 'lodash/last';
import { WithContext, Product, ItemAvailability } from 'schema-dts';
import { Layout } from '../components/Layout';
import { LoaderFullPage } from '../components/Loaders';
import { ProductAdvertisementModule } from '../modules/Advertisement/Product';
import { AdvertisementService } from '../modules/Advertisement/services/AdvertisementService';

const CATEGORY: {
  [key: string]: string;
} = {
  '1': 'Carro',
  '2': 'Moto',
  '3': 'Ônibus',
  '4': 'Carga',
  '5': 'Náutica',
  '6': 'Agrícola',
  '7': 'Serviços',
};

const partsAdvertisementFormatter = (unformattedAd: any) => {
  if (!unformattedAd) {
    return {};
  }
  const { user, description, newPart, partType, modelType, id, ...rest } = unformattedAd;
  const { city, state } = user || {};
  const category: string = modelType ? CATEGORY[modelType] : '';
  return {
    ...rest,
    user,
    city,
    state,
    productDescription: description,
    type: '7',
    productState: newPart ? 'Nova' : 'Usada',
    category,
    productType: partType,
    autoscarId: id,
  };
};

const getPhotoUrl = (photoUrl: string | undefined, returnUndefined?: boolean) => {
  if (!photoUrl && returnUndefined) {
    return undefined;
  }

  if (!photoUrl) {
    return `${process.env.FTP_IMAGENS}images/ad/image-veiculodefault-large.png`;
  }

  return `${process.env.FTP_IMAGENS}${photoUrl}`;
};

const getPath = (location: any) => {
  const path = location.href;

  if (path.substr(path.length - 1) === '/') {
    return path.substr(0, path.length - 1);
  }

  return path;
};

const createSchema = (advertisement: any, url: string): WithContext<Product> => {
  const { price, name, description, photoUrl } = advertisement;

  const image = getPhotoUrl(_first(photoUrl));

  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name,
    offers: {
      '@type': 'Offer',
      price,
      priceCurrency: 'BRL',
      availability: 'InStock' as ItemAvailability,
      priceValidUntil: new Date().toDateString(),
      url,
    },
    description,
    image,
  };
};

const ProductAdvertisementPage = (props: any) => {
  const {
    data: { autoscarPartsAdvertisement },
    pageContext: { id },
    location,
  } = props;

  const advState =
    autoscarPartsAdvertisement && autoscarPartsAdvertisement.autoscarId === id
      ? partsAdvertisementFormatter(autoscarPartsAdvertisement)
      : {};
  const [partsAdvertisement, setPartsAdvertisement] = useState<any>(advState);

  useEffect(() => {
    if (partsAdvertisement.id || partsAdvertisement.autoscarId) {
      return () => undefined;
    }

    const getPartsAdvertisement = async () => {
      const pathId: string = _last(getPath(location).split('/')) || '';
      const { data } = await AdvertisementService.getProductAdvertisement(pathId);
      setPartsAdvertisement(partsAdvertisementFormatter(data));
    };

    getPartsAdvertisement();

    return () => undefined;
  }, [autoscarPartsAdvertisement, id]);

  if (!partsAdvertisement.id && !partsAdvertisement.autoscarId) {
    return <LoaderFullPage />;
  }

  const queryParam = location.pathname;
  const image = getPhotoUrl(_first(partsAdvertisement.photoUrl), true);

  const productSchema = createSchema(partsAdvertisement, location.href);
  return (
    <Layout
      title={`${partsAdvertisement.name} | Autoscar`}
      description={partsAdvertisement.description}
      schema={productSchema}
      image={image}
      url={location.href}
    >
      <ProductAdvertisementModule advertisement={partsAdvertisement} queryParam={queryParam} />
    </Layout>
  );
};

export const query = graphql`
  query ($id: Int) {
    autoscarPartsAdvertisement(autoscarId: { eq: $id }) {
      price
      name
      autoscarId
      description
      warranty
      newPart
      limitCount
      partType
      modelType
      photoUrl
      user {
        autoscarId
        name
        additionalAddressData
        address
        addressDistrict
        addressNumber
        cep
        city
        companyName
        email
        fantasyName
        phone
        state
        type
        urlCompanyLogo
        urlFrontagePhoto
        whatsapp
      }
    }
  }
`;

export default ProductAdvertisementPage;
